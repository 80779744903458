.testimonial__content h2 {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .marginInMobile {
    margin-bottom: 10%;
  }
}

@media screen and (max-width: 390px) {
  .marginInMobile {
    margin-bottom: 10%;
  }
}

/* Add this media query for desktop screens */
@media (min-width: 768px) {
  .testimonial__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .testimonial__img {
    width: 50%;
  }

  .testimonial__content {
    width: 50%;
  }
}