/* CourseCard.css */
.single__course__item {
    display: flex;
    flex-direction: column;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    padding: 16px;
    cursor: pointer;
    transition: box-shadow 0.3s;
    margin-bottom: 20px;
}

.single__course__item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course__img img {
    width: 100%;
    height: 200px;
}

.course__title {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
}

.course__details {
    margin-top: 16px;
}

.course__details p {
    margin: 8px 0;
}

.lesson,
.students {
    display: flex;
    align-items: center;
    gap: 8px;
}

.alert-info {
    background-color: #17a2b8;
    color: #fff;
    padding: 8px;
    border-radius: 4px;
    margin-top: 16px;
    margin-bottom: 6px;
    text-align: center;
}

.rating {
    color: #17bf9e;
}