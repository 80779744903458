.single__course__item {
  padding: 15px;
  background-color: rgba(226, 248, 248, 0.664);
  margin: 2%;
  cursor: pointer
}

.single__course__item:hover {}

.course__img img {
  border-radius: 10px;
  margin-bottom: 20px;
}


.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #17bf9e;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}



.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 2rem;
}

.course__top__left h2 {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .single__course__item {
    margin: 2%;
  }
}

@media screen and (max-width: 390px) {
  .single__course__item {
    margin-bottom: 2%;
  }
}