.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #bcd7cc;
}

.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
}

.about__img img {
  border-radius: 15px;
}

.about__content h2 {
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .about__content {
    padding-left: 0px;
  }
}

@media screen and (max-width: 390px) {
  .about__content {
    padding-left: 0px;
  }
}