.newsletter {
  background: #17bf9e;

  padding: 60px;
  border-radius: 15px;
}

.newsletter h2 {
  color: #fff;
}

.subscribe input {
  border: none;
  outline: none;
}

.subscribe {
  /* padding: 7px 10px; */
  background: rgba(226, 248, 248, 0.664);
  width: 30%;
  margin: auto;
  border-radius: 50px;
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
}

.subscribe:hover {
  background: #d8dcdf;
  color: #000;
  box-shadow: 0 0 10px rgba(36, 77, 98, 0.3);
}

@media screen and (max-width: 768px) {
  .subscribe {
    width: 100%;
  }
}

@media screen and (max-width: 390px) {
  .subscribe {
    width: 100%;
  }
}