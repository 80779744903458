/* Additional styles for .team-page */
.team-page {
    text-align: center;
    padding: 40px 0;
}

/* Additional styles for .team-members */
.team-members {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Additional styles for .team-member */
.team-member {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    margin: 20px;
    width: 300px;
    height: 400px;
    /* Fixed width for consistency */
    transition: transform 0.3s ease-in-out;
    overflow: hidden;
}

.team-member:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

/* Additional styles for .team-member img */
.team-member img {
    /* width: 100%; */
    height: auto;
    object-fit: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/* Additional styles for .team-member details */
.team-member-details {
    padding: 20px;
}

.team-member h2 {
    font-size: 1.8rem;
    margin: 0;
    color: #333;
}

.team-member p {
    padding: 0px 10px 0px 10px;
    font-size: 1.0rem;
    color: #666;
    margin: 10px 0;
}