.landing-page {
    font-family: Arial, sans-serif;
    text-align: center;
}

.header {
    background-color: #fff;
    color: #000;
    /* padding: 20px; */
}

.header h1 {
    font-size: 36px;
}

.hero {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px;
}

.hero-content {
    flex: 1;
    text-align: left;
}

.hero h2 {
    font-size: 32px;
}

.cta-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.hero-image {
    flex: 1;
}

.features {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f5f5f5;
}

.feature {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.feature h3 {
    font-size: 24px;
}

/* Add these styles to your CSS file (LandingPage.css) */

.advantages {
    background-color: #fff;
    padding: 40px;
    text-align: center;
}

.advantages h2 {
    font-size: 28px;
    color: #333;
}

.advantages-list {
    text-align: left;
    font-size: 18px;
    color: #555;
    text-align: center;
}

.advantage-item {
    margin: 10px 0;
}

.bullet {
    display: inline-block;
    width: 1em;
    margin-right: 5px;
    font-weight: bold;
    color: #007bff;
    /* Color for the bullet */
}

/* Add these styles to your CSS file (LandingPage.css) */

.employee-stats {
    background-color: #f5f5f5;
    padding: 40px;
    text-align: center;
}

.employee-stats h2 {
    font-size: 28px;
    color: #333;
}

.employee-stat {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.employee-stat h3 {
    font-size: 24px;
    color: #007bff;
    /* Title color */
}

.employee-stat p {
    font-size: 18px;
    color: #555;
    /* Text color */
}

/* Default styles for larger screens */
.landing-page {
    font-family: Arial, sans-serif;
    text-align: center;
    padding: 20px;
}

.header {
    background-color: #fff;
    color: #000;
}

.hero {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 40px;
}

.hero-content {
    flex: 1;
    text-align: left;
    padding: 20px;
}

.hero h2 {
    font-size: 32px;
}

.cta-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.hero-image {
    flex: 1;
}

.features {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f5f5f5;
}

.feature {
    flex: 1;
    text-align: center;
    padding: 20px;
}

.feature h3 {
    font-size: 24px;
}

/* Styles for smaller screens (e.g., tablets and mobile) */
@media (max-width: 768px) {
    .hero {
        flex-direction: column;
    }

    .hero h2 {
        font-size: 28px;
    }

    .hero-content,
    .hero-image {
        flex: none;
        width: 100%;
    }

    .cta-button {
        font-size: 16px;
    }

    .features {
        flex-direction: column;
        padding: 10px;
    }

    .feature {
        padding: 10px;
    }

    .feature h3 {
        font-size: 20px;
    }

    .advantages h2 {
        font-size: 24px;
    }

    .advantage-item {
        font-size: 16px;
    }

    .employee-stats h2 {
        font-size: 24px;
    }

    .employee-stat h3 {
        font-size: 20px;
    }

    .employee-stat p {
        font-size: 16px;
    }
}